$(document).ready(function() {
  $(".menu-navigation").on("click","a", function (event) {
    event.preventDefault();
    var id  = $(this).attr('href'),
        top = $(id).offset().top;
    $('body,html').animate({scrollTop: top}, 800);
    $("body").removeClass("menu-active-js");
    $(".hamburger").removeClass("is-active");
  });

  $(".hamburger").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("body").toggleClass("menu-active-js");
  });

  new WOW().init();

  $('.reviews-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });
});